<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :key="1"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column label="创建时间" width="93">
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{
                            scope.row.createTime
                                ? scope.row.createTime.split(' ')[0]
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="订单编号" width="140">
                <template slot="header">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;订单编号
                </template>
                <template slot-scope="scope">
                    <div
                        v-if="scope.row.contractStatus == 3"
                        :style="{
                            color: 'red',
                            cursor: 'pointer',
                        }"
                    >
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(scope.row)"
                            class="iconfont icon-fuzhi1"
                        ></el-button>
                        {{ scope.row.orderNo || '- -' }}
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="该订单的关联合同已作废"
                            placement="bottom"
                        >
                            <i
                                v-if="scope.row.contractStatus == 3"
                                class="el-icon-warning"
                                style="color: #ff912a"
                            ></i>
                        </el-tooltip>
                    </div>
                    <div
                        v-else
                        @click="onDetail(scope.row)"
                        :style="{
                            color: scope.row.sourceType == 1 ? '#2370eb' : '',
                            cursor: 'pointer',
                        }"
                    >
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(scope.row)"
                            class="iconfont icon-fuzhi1"
                        ></el-button>
                        {{ scope.row.orderNo || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="部门" width="80">
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        <el-tooltip placement="top"
                            >·
                            <div slot="content">
                                <p
                                    style="line-height: 2"
                                    v-for="item in scope.row
                                        .departmentAdminDOList"
                                    :key="item.id"
                                >
                                    {{ item.departmentName }}
                                </p>
                            </div>
                            <em style="font-style: normal">
                                {{ returnName(scope.row) }}
                            </em>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="员工" width="55">
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{ scope.row.adminName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{ scope.row.companyName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="订单状态" width="150" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                        class="order_status"
                    >
                        {{
                            scope.row.status == 1
                                ? '未交付'
                                : scope.row.status == 2
                                ? '部分交付'
                                : scope.row.status == 3
                                ? '已交付'
                                : '- -'
                        }}

                        <span
                            class="no_btn"
                            @click="onRelation(scope.row)"
                            v-if="scope.row.status != 1"
                            >查看</span
                        >

                        <el-button
                            v-else-if="
                                (scope.row.reminderStatus == 1 ||
                                    (scope.row.reminderStatus == 2 &&
                                        !scope.row.countDown)) &&
                                !btnP.channelorder
                            "
                            type="primary"
                            @click="onReminder(scope.row)"
                            >催单</el-button
                        >
                        <el-tooltip
                            v-else-if="
                                scope.row.reminderStatus == 2 &&
                                scope.row.countDown &&
                                !btnP.channelorder
                            "
                            class="item"
                            effect="dark"
                            placement="top"
                        >
                            <div slot="content">
                                {{ scope.row.countDown }}
                            </div>
                            <span
                                class="no_btn"
                                style="
                                    border: 1px solid #46a136;
                                    color: #46a136;
                                    height: 20px;
                                    line-height: 20px;
                                "
                            >
                                已催单</span
                            >
                        </el-tooltip>
                        <span
                            v-else-if="
                                scope.row.reminderStatus == 2 &&
                                scope.row.countDown &&
                                btnP.channelorder
                            "
                        >
                            <i
                                style="color: #d0021b; right: 45px"
                                class="iconfont icon-xingzhuang no_btn"
                            ></i>
                            <span
                                class="no_btn"
                                style="
                                    border: 1px solid #d0021b;
                                    color: #d0021b;
                                    height: 20px;
                                    line-height: 20px;
                                "
                                >催单中</span
                            >
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="产品名称" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{
                            scope.row.productAttributeRelationBOList &&
                            scope.row.productAttributeRelationBOList.length > 0
                                ? scope.row.productAttributeRelationBOList[0]
                                      .productName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="购买类型" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{ returnOrderType(scope.row) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="用户数" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{ scope.row.orderNumber || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="年限" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{ scope.row.orderTerm || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="应收金额"
                show-overflow-tooltip
                v-if="btnP.receivable"
                width="80"
            >
                <template slot-scope="scope">
                    <div
                        :style="{
                            color: scope.row.contractStatus == 3 ? 'red' : '',
                        }"
                    >
                        {{
                            scope.row.receivableAmount
                                ? scope.row.receivableAmount
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="
                            scope.row.status != 3 &&
                            scope.row.contractStatus != 3
                        "
                        @click="onPay(scope.row)"
                    >
                        交付
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <!-- 关联交付订单 -->
        <el-dialog
            :visible.sync="dialogRelation"
            width="780px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    关联交付订单
                </div>
            </template>
            <Relation ref="relation" @close="handleClose" />
        </el-dialog>
        <!-- s设置状态 -->
        <el-dialog
            :visible.sync="dialogSetUp"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    设置订单状态
                </div>
            </template>
            <SetUp ref="setUp" @close="handleClose" />
        </el-dialog>
    </div>
</template>

<script>
import { orderList, orderUpdate } from '@/api/order/order.js';
import Relation from '../relationOrder/relation.vue';
import SetUp from '../setUpStatus/setUp.vue';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            userType: sessionStorage.getItem('userType'),
            dialogRelation: false,
            dialogSetUp: false,
            timer: null,
            isClick: true,
        };
    },
    components: { Relation, SetUp },

    methods: {
        getData(searchVal, pageNum, pagesize, btnP) {
            this.btnP = btnP;
            let pageData = {
                param: searchVal,
                pageSize: pagesize,
                pageNum: pageNum,
            };
            sessionStorage.setItem('pageData', JSON.stringify(pageData));
            let data = {
                param: {},
                pageNum,
                pageSize: pagesize,
            };
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            if (searchVal.status == '未交付') {
                data.param.status = 1;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (searchVal.status == '部分交付') {
                data.param.status = 2;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (searchVal.status == '已交付') {
                data.param.status = 3;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (searchVal.status == '异常') {
                data.param.contractStatus = 3;
                data.param.statusList = [1, 2, 3];
            } else {
                data.param.statusList = [1, 2, 3];
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            }
            if (!btnP.depSearch && searchVal.adminValText == '我的') {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            if (!btnP.userSearch && !btnP.depSearch) {
                if (searchVal.adminValText == '我的') {
                    data.param.adminId = sessionStorage.getItem('adminId');
                } else if (searchVal.adminValText == '合作') {
                    data.param.collaboratorAdminId =
                        sessionStorage.getItem('adminId');
                } else if (searchVal.adminValText == '协作') {
                    data.param.cooperationAdminId =
                        sessionStorage.getItem('adminId');
                }
            }
            data.param.productCategory = 12;
            if (searchVal.departmentId && searchVal.adminValText == '我的') {
                data.param.departmentId = searchVal.departmentId;
            }
            if (searchVal.adminId) {
                if (searchVal.adminValText == '我的') {
                    data.param.adminId = searchVal.adminId;
                } else if (searchVal.adminValText == '合作') {
                    data.param.collaboratorAdminId = searchVal.adminId;
                } else if (searchVal.adminValText == '协作') {
                    data.param.cooperationAdminId = searchVal.adminId;
                }
            }
            if (searchVal.orderType) {
                data.param.orderType = searchVal.orderType;
            }
            if (searchVal.sourceType == '渠道') {
                data.param.sourceType = 2;
                delete data.param.contractStatusList;
            } else if (searchVal.sourceType == '直销') {
                data.param.sourceType = 1;
            }
            if (!btnP.ordersource) {
                data.param.sourceType = 1;
            }
            if (searchVal.createMonth) {
                data.param.createMonth = searchVal.createMonth;
            }
            if (searchVal.searchDate) {
                data.param.startCreateDate = searchVal.searchDate[0];
                data.param.endCreateDate = searchVal.searchDate[1];
            }
            orderList(data).then((res) => {
                if (res.code == 200) {
                    res.data.list.forEach((item) => {
                        item.disabled1 = true;
                        item.disabled2 = true;
                        item.countDown = '';
                        item.isClick = true;
                    });
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                    // this.$nextTick(() => {
                    //     this.$refs.multipleTable.doLayout();
                    // });
                    this.timer = setInterval(() => {
                        this.tableData.forEach((item) => {
                            if (item.reminderStatus == 2) {
                                item.countDown = this.countDown(
                                    item.reminderTime
                                );
                            }
                        });
                    }, 0);
                }
            });
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        onPay(row) {
            this.$router.push({
                path: '/addorder',
                query: {
                    type: 12,
                    orderId: row.id,
                    orderNo: row.orderNo,
                },
            });
        },
        onReminder(row) {
            this.$emit('onReminder', row);
        },
        onAdd(data) {
            this.$emit('onAdd', data);
        },
        onDetail(data) {
            if (this.btnP.details) {
                this.$emit('onDetail', data);
            }
        },
        onSettleAmount(row) {
            row.disabled1 = false;
        },
        updateSettleAmount(row) {
            let data = {
                param: {
                    id: row.id,
                    settleAmount: row.settleAmount,
                },
            };
            orderUpdate(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.searchEmpty();
                }
            });
        },
        onGuideAmount(row) {
            row.disabled2 = false;
        },
        updateGuideAmount(row) {
            let data = {
                param: {
                    id: row.id,
                    guideAmount: row.guideAmount,
                },
            };
            orderUpdate(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.searchEmpty();
                }
            });
        },
        searchEmpty() {
            this.$emit('searchEmpty');
        },
        returnOrderType(data) {
            let a = '- -';
            data.productAttributeRelationBOList.forEach((item) => {
                if (item.attributeColumnName == 'productType') {
                    item.productAttributeValueBOList &&
                    item.productAttributeValueBOList.length > 0
                        ? item.productAttributeValueBOList.forEach((itm) => {
                              if (data.orderType == itm.attributeValue) {
                                  a = itm.attributeValueName;
                              }
                          })
                        : '- -';
                }
            });
            return a;
        },
        num(item) {
            if (
                item.productCategory == 1 ||
                item.productCategory == 2 ||
                item.productCategory == 3 ||
                item.productCategory == 4
            ) {
                var num = 0;
                item.orderPlanBOList.forEach((itm) => {
                    num += Number(itm.term);
                });
                // // console.log(item.orderPlanBOList);
                // if (item.orderPlanBOList.length == 0) {
                //   // console.log(num - item.orderTerm >= 0);
                // }
                return num - item.orderTerm >= 0 ? false : true;
            } else {
                if (item.orderPlanBOList && item.orderPlanBOList.length == 0) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        getStatus2(arr) {
            let a = JSON.parse(JSON.stringify(arr)).filter((item) => {
                return item.status == 2;
            });
            return a.length;
        },
        onEdit(data) {
            this.$emit('onEdit', data);
        },
        onRelation(row) {
            this.dialogRelation = true;
            setTimeout(() => {
                this.$refs.relation.getData(row);
            });
        },
        onSetUp(row) {
            this.dialogSetUp = true;
            setTimeout(() => {
                this.$refs.setUp.getData(row);
            });
        },
        handleClose() {
            this.dialogRelation = false;
            this.dialogSetUp = false;
            this.$emit('searchEmpty');
        },
        // 复制
        onCopy(item) {
            let url = item.orderNo;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        countDown(setTime) {
            // let a = setInterval(() => {
            var newDate = new Date().getTime();
            var times =
                (new Date(setTime).getTime() + 4 * 60 * 60 * 1000 - newDate) /
                1000;
            var h = parseInt(times / 60 / 60); //时
            var m = parseInt((times / 60) % 60); //分
            m = m < 10 ? '0' + m : m;

            var s = parseInt(times % 60); //秒
            if (s < 0) {
                clearInterval(this.timer);
                this.timer = null;
                return 0;
            }
            return '请于' + h + '小时' + m + '分后再次尝试催单';
            // }, 1000);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
}
.tooltip_con_bot {
    //   padding-top: 30px;
    p {
        margin-bottom: 5px;
        span {
            display: inline-block;
            width: 60px;
        }
    }
}
.left_toast {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 12px;
    line-height: 1.2;
}
.order_status {
    position: relative;
    button {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        width: 50px;
        height: 22px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #2370eb;
        cursor: pointer;
        color: #2370eb;
        margin-left: 16px;
        text-align: center;
    }
    .no_btn {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        cursor: pointer;
        color: #2370eb;
        display: block;
        width: 50px;
        text-align: center;
    }
}
</style>
